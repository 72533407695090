var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Please give us more details about your platform.")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{attrs:{"name":"website","rules":{
            required: true,
            regex: /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"website","type":"text","error":failed ? 'The Website field is required & URL matching': null,"hasSuccess":passed,"placeholder":"ex: http://www.creative-tim.com","label":"Your website"},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"framework","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"framework","error":failed ? 'The Framework field is required': null,"hasSuccess":passed,"placeholder":"ex: Vue.js","label":"Framework Type"},model:{value:(_vm.framework),callback:function ($$v) {_vm.framework=$$v},expression:"framework"}})]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"Language","error":failed ? 'The Language field is required': null}},[_c('el-select',{staticClass:"select-default",attrs:{"name":"language"},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},_vm._l((_vm.languageOptions),function(lang){return _c('el-option',{key:lang.value,staticClass:"select-default",attrs:{"label":lang.label,"value":lang.value}})}),1)],1)]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"bootstrap version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"bootstrap version","error":failed ? 'The Bootstrap field is required': null,"label":"Bootstrap version"}},[_c('el-select',{staticClass:"select-default",model:{value:(_vm.bootstrapVersion),callback:function ($$v) {_vm.bootstrapVersion=$$v},expression:"bootstrapVersion"}},[_c('el-option',{staticClass:"select-default",attrs:{"value":"1.1"}},[_vm._v("Bootstrap 1.1")]),_c('el-option',{staticClass:"select-default",attrs:{"value":"2.0"}},[_vm._v("Bootstrap 2.0")]),_c('el-option',{staticClass:"select-default",attrs:{"value":"3.0"}},[_vm._v("Bootstrap 3.0")]),_c('el-option',{staticClass:"select-default",attrs:{"value":"4.0"}},[_vm._v("Bootstrap 4.0(alpha)")])],1)],1)]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"price","label":"Price","error":failed ? 'The Bootstrap field is required': null}},[_c('el-input-number',{attrs:{"placeholder":"ex: 19.00","name":"price"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }